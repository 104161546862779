*,
*::after,
*::before {
  box-sizing: border-box;
}

/* @font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v10/rP2Hp2ywxg089UriCZ2IHSeH.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v10/rP2Hp2ywxg089UriCZOIHQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v10/rP2Cp2ywxg089UriAWCrCBamC2QX.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v10/rP2Cp2ywxg089UriAWCrCBimCw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v10/rP2Cp2ywxg089UriASitCBamC2QX.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v10/rP2Cp2ywxg089UriASitCBimCw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
} */

@font-face {
  font-family: 'DMSans-Bold';
  src: url(./assets/fonts/DMSans-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'DMSans';
  src: url(./assets/fonts/DMSans-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DMSans';
  src: url(./assets/fonts/DMSans-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: 'DMSans-Medium';
  src: url(./assets/fonts/DMSans-Medium.ttf);
}

@font-face {
  font-family: 'DMSans-Medium';
  src: url(./assets/fonts/DMSans-MediumItalic.ttf);
  font-style: italic;
}

@font-face {
  font-family: 'DMSans';
  src: url(./assets/fonts/DMSans-Regular.ttf);
}

body {
  margin: 0;
  font-family: 'DMSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* @media screen and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

*::-webkit-scrollbar {
  width: 6px;
  /* height: 10px; */
  border-radius: 4px;
  opacity: 0.7;
  display: none;

}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 0.7;
  border-radius: 4px;
  display: none;

  /* height: 10px; */

}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  display: none;
  opacity: 0.7;
  outline: 1px solid slategrey;
  border-radius: 4px;
  /* height: 10px; */
}

.divider {
  width: 100%;
  transform: scaleX(0.96);
  display: none;
  height: .6px;
  background-color: #ffffff7f;
}

@media screen and (min-width: 500px) {
  .divider {
    display: unset;
  }
}

footer {
  margin: 2rem 0;
}

body,
#root {
  /* background-color: #0C111B;
  background: #0C111B; */
  background-image: url(./assets/images/background.jpg);
  /* background-color: rgba(0, 0, 0, 0.313);
  backdrop-filter: blur(10px); */
  background-size: cover;
  background-repeat: repeat-y;
  overflow-x: hidden;
  background-blend-mode: overlay;
  /* z-index: 1000; */
}

body::before {
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  z-index: 2;
}

body,
html {
  min-height: calc(var(--vh, 1vh) * 100);
  scroll-behavior: smooth;
}

/* body,
#root {
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
  max-height: calc(var(--vh, 1vh) * 100);
}

html {
  height: fill-available;
  height: -webkit-fill-available;
  max-height: calc(var(--vh, 1vh) * 100);
} */

button,
input[type='button'],
input[type='submit'] {
  outline: none;
  border: none;
  pointer-events: auto;
}

.blur {
  backdrop-filter: blur(4px);
}

.gap {
  gap: 1rem;
}

button {
  cursor: pointer;
  color: black;
}